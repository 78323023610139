<template>
  <div class="AfterSales p32 p-t-32">
    <div class="tt df">
      <div class="suxian"></div>
      售后管理
    </div>
    <div class="navlist">
      <div v-for="(item, index) in options" :key="index" @click="activeList(item.value)" class="li" :class="active === item.value ? 'active' : ''">
        {{item.label}}
      </div>
    </div>
    <div class="serchtab">
      <div class="li">
        <div class="serchName">下单时间</div>
        <div class="ipt">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="inputDate"
            @change="serchList"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="li">
        <div class="serchName">申请状态</div>
        <div class="ipt">
          <el-select v-model="value" placeholder="请选择" @change="serchList">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="serch">
        <el-button @click="serchList" type="success">搜索</el-button>
        <el-button @click="resetFn">重置</el-button>
      </div>
    </div>

    <div class="tabel">
      <el-table
        :cell-style="cellStyle"
        :header-cell-style="{ background: '#F8F8F8' }"
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column align="left" type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          align="center"
          property="createTime"
          label="申请时间"
          width="auto"
        >
        </el-table-column>
        <el-table-column
          align="center"
          property="applySn"
          label="申请编号"
          width="auto"
        >
        </el-table-column>
        <el-table-column
          align="center"
          property="statu"
          label="申请状态"
          width="auto"
        >
          <template slot-scope="scope">
            <div class="df center aic tac">
              <div
                style="margin-right: 5px"
                class="del"
                :style="
                  scope.row.completeStatus === 140
                    ? '  background-color:#26C487'
                    : '  background-color:#FFA936'
                "
              ></div>{{ getLabelByValue(scope.row.completeStatus, options) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="applyCause"
          label="申请理由"
          width="auto"
        >
        </el-table-column>
        <el-table-column
          align="center"
          property="orderSn"
          label="原订单编号"
          width="auto"
        >
        </el-table-column>
        <el-table-column align="right" label="操作" width="120">
          <template slot-scope="scope">
            <div @click="goDetail(scope.row)" class="cup info">详情</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="total > 0" class="pagenation">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="10"
        layout="prev, pager, next, total,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { applyRefundList, applyRefundDetail } from "@/request/Orders/order";
import { getLabelByValue } from '@/utils/tool'
export default {
  data() {
    return {
      getLabelByValue, 
      page: 1,
      active: -1,
      total: 0,
      options: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 100,
          label: "待审核",
        },
        {
          value: 110,
          label: "待回寄",
        },
        {
          value: 120,
          label: "待入库验收",
        },
        {
          value: 130,
          label: "待入账",
        },
        {
          value: 140,
          label: "已入账",
        },
        {
          value: 150,
          label: "审核驳回",
        },
      ],
      value: "",
      //下单时间
      inputDate: null,
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    //搜索
    serchList() {
      this.page = 1;
      this.getList();
    },
    //获取列表
    getList() {
      let data = {
        // associatedCustomer: "",//关联客户
        completeStatus: this.value || this.active || -1,
        // endDate: "",
        isAsc: "asc",
        customerAddrId: this.$store.state.AddrId,
        pageNum: this.page,
        pageSize: 10,
        startDate: this.inputDate,
        userId: this.$store.state.owerId,
      };
      applyRefundList(data).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }
      });
    },
    //重置
    resetFn() {
      this.inputDate = "";
      this.value = "";
      this.getList();
    },
    goDetail(item) {
      applyRefundDetail({ applyId: item.id }).then((res) => {
        if (res.code === 200) {
          let data = { ...res.data, id: item.id };
          this.$router.push({
            name: `AfterseleDetail`,
            params: data,
          });
        }
      });

      // let data ={  applyId:item.id, Aftersales: 1 ,id:item.mallOrderResDTO.id,completeStatus:item.completeStatus}
    },
    changeDate() {
      var date = new Date(this.input);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      //   let time = y + '-' + m + '-' + d+' '+h+':'+minute;
      let time = y + "-" + m + "-" + d;
    },
    activeList(index) {
      // 订单状态：-1全部 待审核-100 待回寄-110 待入库验收-120 待入账-130 已入账-140 审核驳回-150
      this.active = index;
      this.getList();
    },
    clearInput(it) {
      if (it == 1) {
        this.input = "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-select .el-input .el-select__caret {
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range-separator {
    padding: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range__icon {
    height: 32px;
  }

  .el-date-editor .el-range__close-icon {
    height: 32px;
  }
  .el-button {
    height: 32px;
    padding: 7px 18px;
  }
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 35px;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.center {
  justify-content: center;
}
.info {
  color: #26c487;
  font-size: 14px;
}
.del {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.serch {
  flex: 1;
  text-align: right;
}
.serchtab {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .li {
    margin-right: 70px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    .serchName {
      font-size: 12px;
      font-family: 'regular';
      font-weight: 400;
      color: #333333;
      margin-right: 20px;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      flex: 1;
    }
  }
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
}
.navlist {
  display: flex;
  .li {
    cursor: pointer;
    text-align: center;
    margin-right: 20px;
    padding: 0 20px;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .active {
    font-size: 16px;
    font-family: 'medium';
    font-weight: 500;
    color: #26c487;
    color: #26c487;
    border-bottom: 4px solid #26c487;
  }
}
</style>
